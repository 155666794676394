/* eslint-disable no-undefined */
/* eslint-disable max-depth */
/* eslint-disable no-console */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import md5 from 'md5'
import { clean } from 'rut.js'
import ErrorPage from '@mymoons/error-page'
import styles from './Checkout.module.css'
import Header from '../Header/Header'
import Services from '../../Apis/Back4AppServices'
import ExtraProducts from '../ExtraProducts/ExtraProducts'
import TotalPrice from '../TotalPrice/TotalPrice'
import messages from './Checkout.messages'
import queryString from 'query-string'
import ProductInformation from '../ProductInformation/ProductInformation'
import PaymentServices from './PaymentServices'
import moment from 'moment'
import {
  INITIAL_STEP,
  PERSONAL_DATA_STEP,
  ADDRESS_STEP,
  PAYMENTS_STEP,
  IS_MEXICO,
  IS_COLOMBIA,
  IS_CHILE,
  IS_PERU,
} from '../../Utils/constants'
import CheckoutForms from '../CheckoutForms/CheckoutForms'
import validator from '../../Utils/validatorForm'
import Footer from '../Footer/Footer'
import AddressSaved from '../AddressSaved/AddressSaved'
import checkoutBack from '../../Apis/CheckoutBack'
import {
  transformExtraProduct,
  installmentsAllowed,
  setAnalyticsUserId,
  setTrackingPatient
} from '../../Utils/utils'
import sentToCRM from '../../Apis/BlackBox'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'
import TagManager from '../../Utils/tagManager'
import { ErrorException } from '../../Utils/error-exception'
import { displayWidget } from '../../Utils/messagebird'

/**
 * Checkout Component for Moons
 * @param {String} productsKeyName - Array of productID to sell
 * @param {String} paymentKeyName - id of the paymentLink given to a customer
 * @param {bool} showPersonalData - show the PersonalData component
 * @param {bool} showAddress - show the Address component
 * @param {bool} showCoupon - show the coupon component
 * @param {bool} extraProductsAllowed - show the extra products component
 * @param {Object} customerData - customer data required if personalData component is false
 * @param {String} customerId - the id of the customer of moons
 * @returns {React.Component} Checkout .
 */
class Checkout extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    this.state = {
      currentProduct:
        (props.productsKeyName && props.productsKeyName[0]) ||
        props.paymentKeyName,
      currentExtraProducts: [],
      data: [],
      dataExtra: [],
      currentCoupon: null,
      paymentMethodSelected: 'card',
      monthsAllowed: null,
      finalPrice: 0,
      pricePrincipalProduct: 0,
      formError: {},
      formData: {},
      error: null,
      queryParamError: null,
      success: null,
      loader: false,
      isMoons: false,
      applyCoupon: true,
      externalPromo: false,
      history: props.history,
      showAddressByProduct: props.showAddress,
      installments: {
        enable: false,
        type: '',
        monthSelected: 0,
        minInstallmentsAmount: 0,
        priceInstallments: null,
        discountPrice: 0,
      },
      tokens: {
        stripeId: null,
        mercadoPagoId: null,
        conektaId: null,
        payuId: null,
        cardType: null,
        paymentTypeId: null,
      },
      formPSE: {
        bank: '',
        clientType: '',
        docType: '',
        docValue: '',
      },
      steps: [
        {
          name: INITIAL_STEP,
          current: true,
        },
        {
          name: PERSONAL_DATA_STEP,
          current: false,
        },
        {
          name: ADDRESS_STEP,
          current: false,
        },
        {
          name: PAYMENTS_STEP,
          current: false,
        },
      ],
    }

    this.isPaymentLink = !!props.paymentKeyName
    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY)
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY)
    window.Mercadopago.setPublishableKey(process.env.REACT_APP_MERCADO_PAGO_KEY)
    window.payU.setURL(process.env.REACT_APP_PAYU_URL)
    window.payU.setPublicKey(process.env.REACT_APP_PAYU_PUBLIC_KEY)
    window.payU.setAccountID(process.env.REACT_APP_PAYU_ID)
    window.payU.setListBoxID('cardID')
    this.refCheckoutForm = createRef(null)
    this.refContainer = createRef(null)

    this.country = process.env.REACT_APP_COUNTRY
    if (this.country === 'Colombia') {
      PaymentServices.createPayuScripts()
    }
    this.newFlow = true
    this.customerId =
      (props.patient && props.patient.CustomerId) ||
      (props.patient && props.patient.customerId)

    this.publicKey = props.patient && props.patient.PublicKey
    this.userDashboard = !!(
      props.patient && props.patient.User_Dashboard == 'Phase 2'
    )
    this.tagManager = new TagManager()
  }

  componentDidMount = async () => {
    displayWidget('checkout')

    const { extraProductsAllowed, patient } = this.props
    const { queryParamError } = this.state

    window.addEventListener('keyup', this.guessPaymentMethod)

    this.tagManager.InitEvent()

    if (queryParamError) {
      return
    }
    await this.getProductsInformation()
    if (extraProductsAllowed) await this.getExtraProductsData()

    this.setInstallments()

    this.initializeFormError()
    this.initializeFormData()

    this.referralCode = patient && patient.Referral_Code
  }

  /**
   * Component Did Update
   * @param {Object} prevProps .
   * @param {Object} prevState .
   * @returns {void} .
   */
  componentDidUpdate = (prevProps, prevState) => {
    const {
      steps,
      data,
      currentProduct,
      currentExtraProducts,
      currentCoupon,
      queryParamError,
      showAddressByProduct,
    } = this.state
    const { showPersonalData } = this.props
    if (queryParamError) {
      return
    }

    if (prevState.currentProduct !== currentProduct) {
      this.setInstallments()
    }

    if (
      prevState.currentProduct !== currentProduct ||
      prevState.currentExtraProducts !== currentExtraProducts ||
      prevState.data !== data ||
      prevState.currentCoupon !== currentCoupon
    ) {
      this.calculateFinalPrice()
    }

    if (prevState.steps !== steps) {
      const currentStep = steps.filter((step) => step.current === true)[0]
      if (currentStep.name === INITIAL_STEP) {
        this.setInstallments()
      }
    }

    if (prevState.steps !== steps) {
      const isBack = this.isBack(prevState.steps, steps)
      const currentStep = steps.filter((step) => step.current === true)[0]
      if (
        (currentStep.name === PERSONAL_DATA_STEP && !showPersonalData) ||
        (currentStep.name === ADDRESS_STEP && !showAddressByProduct)
      ) {
        this.submitData(isBack, true)
      }
    }
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    window.removeEventListener('keyup', this.guessPaymentMethod)
  }

  guessPaymentMethod = () => {
    const { formData, finalPrice } = this.state
    const { payment } = formData
    const bin = payment && payment.card ? payment.card.substring(0, 6) : ''
    if (bin.length >= 6) {
      window.Mercadopago.getPaymentMethod({ bin }, (status, response) => {
        if (status === 200) {
          const paymentMethodId = response[0] && response[0].id
          this.getInstallments(paymentMethodId, finalPrice)
        } else {
          this.setState({ monthsAllowed: [] })
        }
      })
    } else if (bin.length === 0) {
      this.setState({ monthsAllowed: null })
    }
  }

  /**
   * Get MP Installments
   * @param {String} id .
   * @param {String} price .
   * @returns {void} .
   */
  getInstallments = (id, price) => {
    const monthsDefault = installmentsAllowed(this.country)
    const monthsAllowed = []
    window.Mercadopago.getInstallments(
      {
        payment_method_id: id,
        amount: price,
      },
      (status, response) => {
        if (status === 200 && response.length > 0) {
          response[0].payer_costs.forEach((installment) => {
            const month = installment.installments
            if (monthsDefault.includes(month)) {
              monthsAllowed.push(month)
            }
          })
          this.setState({ monthsAllowed })
        } else {
          this.setState({ monthsAllowed: null })
        }
      }
    )
  }

  /**
   * Set PSE Data
   * @param {Object} pseObj .
   * @returns {void} .
   */
  setPSEData = (pseObj) => {
    this.setState((prevState) => ({
      formPSE: { ...prevState.formPSE, ...pseObj },
    }))
  }

  setInstallments = () => {
    const { data, currentProduct, queryParamError } = this.state
    const { patient } = this.props
    if (queryParamError) {
      return
    }
    const dataProduct = data.filter(
      (dataProd) =>
        dataProd.keyName === currentProduct ||
        dataProd.paymentKey === currentProduct
    )[0]
    const patientHasInstallments = patient && patient.Installments
    const installmentsObj = {
      enable: patientHasInstallments
        ? patient.Installments
        : dataProduct.installments,
      type: patientHasInstallments
        ? patient.Installments_Type
        : dataProduct.installmentsType,
      minInstallmentsAmount: patientHasInstallments
        ? 3000
        : dataProduct.minInstallmentsAmount || 0,
      discountPrice: patientHasInstallments
        ? patient.Discount_Price
        : dataProduct.discountPrice,
      priceInstallments: 0,
      monthSelected: 0,
    }
    this.setState((prevState) => ({
      installments: {
        ...prevState.installments,
        ...installmentsObj,
      },
    }))
  }

  /**
   * Change Final Price By Installments
   * @param {Integer} monthSelected .
   * @param {Integer} newFinalPrice .
   * @returns {void} .
   */
  changeFinalPriceByInstallments = (monthSelected, newFinalPrice) => {
    const { installments, finalPrice, data, currentProduct } = this.state
    const { type, discountPrice } = installments

    const currentDataPrincipal = data.filter(
      (dataProd) =>
        dataProd.keyName === currentProduct ||
        dataProd.paymentKey === currentProduct
    )[0]

    const isAdvance =
      currentDataPrincipal && currentDataPrincipal.advancePayment

    const discountTotal = isAdvance
      ? finalPrice
      : (finalPrice * 100) / (100 - discountPrice)
    let priceInstallments = monthSelected ? newFinalPrice : finalPrice

    if (type === 'discount_one_payment') {
      priceInstallments = monthSelected ? discountTotal : finalPrice
    }
    this.setState((prevState) => ({
      installments: {
        ...prevState.installments,
        monthSelected,
        priceInstallments,
      },
    }))
  }

  /**
   * @param {String} prevStep .
   * @param {String} currentStep .
   * @returns {void}
   */
  isBack = (prevStep, currentStep) => {
    const lastIndex = prevStep.findIndex((step) => step.current === true)
    const currentIndex = currentStep.findIndex((step) => step.current === true)
    if (lastIndex > currentIndex) return true
    return false
  }

  /**
   * @param {String} value .
   * @return {void} .
   */
  handlePaymentSelection = (value) => {
    this.setState({ paymentMethodSelected: value })
  }

  initializeFormError = () => {
    const formError = {
      personalDataError: {
        nameError: false,
        emailError: false,
        numberError: false,
      },
      addressError: {
        streetError: false,
        internalNumberError: false,
        addressNumberError: false,
        zipCodeError: false,
        colonyError: false,
        stateError: false,
        cityError: false,
        referenceError: false,
      },
      paymentError: {
        cardError: false,
        cardNameError: false,
        cvcError: false,
        yearError: false,
        monthError: false,
        dniTypeError: false,
        dniValueError: false,
      },
      pseError: {
        bankError: false,
        clientTypeError: false,
        docError: false,
      },
    }
    this.setState({ formError })
  }

  initializeFormData = () => {
    const { customerData, patient } = this.props
    let internalNumber = ''
    let addressNumber = ''
    if (
      (IS_CHILE || IS_PERU) &&
      patient &&
      patient.Address_Number &&
      patient.Address_Number.includes(' - INT: ')
    ) {
      const splitAddress = patient.Address_Number.split(' - INT: ')
      addressNumber = splitAddress[0]
      internalNumber = splitAddress[1]
    } else {
      addressNumber = (patient && patient.Address_Number) || ''
    }
    const formData = {
      personalData: {
        name: customerData ? customerData.name : '',
        email: customerData ? customerData.email : '',
        number: customerData ? customerData.phone : '',
      },
      address: {
        street: (patient && patient.Street) || '',
        addressNumber,
        internalNumber,
        zipCode: (patient && patient.Zip_Code) || '',
        colony: (patient && patient.Neighborhood) || '',
        state: (patient && patient.State) || '',
        city: (patient && patient.City) || '',
        reference: (patient && patient.References) || '',
      },
      payment: {
        card: '',
        cardName: '',
        cvc: '',
        year: '',
        month: '',
        dniType: '',
        dniValue: '',
      },
    }
    this.setState({ formData })
  }

  /**
   * Change Steps
   * @param {boolean} back value to return to previous step
   * @param {boolean} notValidate value to no validate the form and change next.
   * @returns {void} .
   */
  submitData = async (back, notValidate) => {
    try {
      const { steps, showAddressByProduct, showPersonalData, paymentMethodSelected, currentProduct, finalPrice, tokens } = this.state
      const newSteps = JSON.parse(JSON.stringify(steps))
      let count = 1
      const indexCurrentStep = steps.findIndex((step) => step.current === true)
      const nameCurrentStep = steps[indexCurrentStep].name
      const { patient } = this.props
      this.clearPaymentMethods(nameCurrentStep, back)

      if (
        this.refCheckoutForm &&
        this.refCheckoutForm.current &&
        !notValidate &&
        !back
      ) {
        const formError = this.validateForm(
          newSteps[indexCurrentStep],
          indexCurrentStep
        )
        if (formError) return
        const currentDate = moment().format('YYYYMMDDTHHmmss')

        let paymentGateway = Object.entries(tokens)
          .filter(([name, value]) => value !== null)
          .map(([name, value]) => ({ name, value }));
        const productKeyName = currentProduct.keyName
        let paymentType = 'payoff'
        if (
          productKeyName &&
          (productKeyName.includes('appointment') ||
            productKeyName.includes('homekit'))
        ) {
          paymentType = 'advance'
        }
        let tracking = await setTrackingPatient(patient)
        const payment_type = (patient.Payment_Status === 'Partial Payment') ? 'Partial' : 'Complete';
        if (nameCurrentStep === PAYMENTS_STEP) {
          if (tracking && window.freshpaint) {
            window.freshpaint.track(
              'Old Checkout - Payment Attempted',
              {
                distinct_id: patient.CustomerId,
                customer_id: patient.CustomerId,
                platform: 'checkout_v2',
                public_key: patient.PublicKey,
                country_ops: patient.Country_Ops,
                currency: patient.Currency,
                created_time: patient.Created_Time,
                status: patient.Payment_Status,
                payment_method: paymentMethodSelected,
                transaction_type: this.isPaymentLink ? 'paymentLink' : 'product',
                payment_gateway: paymentGateway.name,
                product_category: this.state.data[0].category,
                product: currentProduct,
                transaction_amount: finalPrice,
                payment_type: payment_type,
                payment_date: currentDate
              })
          }
        }
      }

      if (back && indexCurrentStep > 0) count = -1
      else if (back && indexCurrentStep === 0) count = 0

      if (nameCurrentStep === PAYMENTS_STEP && !back) {
        this.setState({ loader: true })
        this.prepareData()
      } else {
        newSteps[indexCurrentStep].current = false
        newSteps[indexCurrentStep + count].current = true
        this.setState({ steps: newSteps })

        if (!back) {
          // save in abandoned cart
          const nameNextStep = steps[indexCurrentStep + count].name
          this.saveInAbandonedCart(nameCurrentStep, nameNextStep)

          this.tagManager.StepsEvent(
            nameNextStep,
            showPersonalData,
            showAddressByProduct
          )
        }
      }
    } catch (e) {
      throw new ErrorException(e.message, e.name, { e })
    }
  }

  /**
   * Clear Payment Methods
   * @param {string} nameCurrentStep .
   * @param {boolean} back .
   * @returns {void} .
   */
  clearPaymentMethods = (nameCurrentStep, back) => {
    const { selector } = this.props
    if (back && nameCurrentStep === PAYMENTS_STEP && selector === 2) {
      this.setState({ paymentMethodSelected: '' })
    }
  }

  prepareData = () => {
    const { formData, isMoons, currentProduct } = this.state
    const { patient } = this.props
    const { personalData, address } = formData
    const data = {
      ...personalData,
      address,
      patient: patient || false,
      country: this.country,
    }
    const productKeyName = currentProduct.keyName
    let paymentType = 'payoff'
    if (
      productKeyName &&
      (productKeyName.includes('appointment') ||
        productKeyName.includes('homekit'))
    ) {
      paymentType = 'advance'
    }

    if (isMoons) {
      sentToCRM(data, this.handleSubmit, this.handleError)
    } else {
      this.handleSubmit()
    }
  }

  /**
   * Save in abandoned cart
   * @param {string} nameCurrentStep .
   * @param {string} nameNextStep .
   * @returns {void} .
   */
  saveInAbandonedCart = (nameCurrentStep, nameNextStep) => {
    const { showPersonalData } = this.props
    const { formData, steps, showAddressByProduct } = this.state
    const { personalData } = formData
    const data = {
      nameStep: nameNextStep,
      attempts: 1,
      ...personalData,
      newFlow: this.newFlow,
    }
    if (
      (nameCurrentStep === PERSONAL_DATA_STEP && showPersonalData) ||
      (nameCurrentStep === ADDRESS_STEP && showAddressByProduct) ||
      nameCurrentStep === PAYMENTS_STEP
    ) {
      Services.saveAbandonedCard(data, steps)
      this.newFlow = false
    }
  }

  /**
   * Validate Form
   * @param {String} value step to validate
   * @param {String} index position the current steps
   * @returns {void} .
   */
  validateForm = (value, index) => {
    try {
      const { formPSE, formData, paymentMethodSelected } = this.state
      const { personalData, address, payment } = formData
      let dataError = false
      const { formError } = this.state
      if (index !== 0) {
        switch (value.name) {
          case PERSONAL_DATA_STEP:
            dataError = validator.validatePersonalData(personalData)
            break
          case ADDRESS_STEP:
            dataError = validator.validateAddress(address)
            break
          case PAYMENTS_STEP:
            if (paymentMethodSelected === 'pse') {
              dataError = validator.validatePSEData(formPSE)
            } else if (paymentMethodSelected === 'card') {
              dataError = validator.validateCardData(payment)
            }
            break
          default:
            break
        }
      }
      if (dataError)
        this.setState({ formError: { ...formError, ...dataError } })
      else this.initializeFormError()
      return dataError
    } catch (validateFormError) {
      if (validateFormError.message) {
        throw new ErrorException(
          validateFormError.message,
          validateFormError.name,
          validateFormError.object()
        )
      } else {
        throw new ErrorException(
          'Error in validateForm function',
          'validateForm',
          {
            validateFormError,
          }
        )
      }
    }
  }

  /**
   * Calculate Final Price
   * @returns {void} .
   */
  calculateFinalPrice = () => {
    const {
      currentProduct,
      currentExtraProducts,
      data,
      dataExtra,
      currentCoupon,
    } = this.state

    const { patient } = this.props

    const currentDataPrincipal = data.filter(
      (dataProd) =>
        dataProd.keyName === currentProduct ||
        dataProd.paymentKey === currentProduct
    )[0]
    let pricePrincipalProduct =
      currentProduct && currentDataPrincipal && currentDataPrincipal.finalPrice

    const isMoons =
      currentProduct &&
      currentDataPrincipal &&
      currentDataPrincipal.productType === 'moons'

    const category =
      currentDataPrincipal &&
      currentDataPrincipal &&
      currentDataPrincipal.category

    let priceExtra = 0
    if (currentExtraProducts) {
      for (const index in currentExtraProducts) {
        if ({}.hasOwnProperty.call(currentExtraProducts, index)) {
          const currentDataExtra = dataExtra.filter(
            (dataExt) => dataExt.keyName === index
          )
          priceExtra +=
            currentDataExtra[0].finalPrice *
            currentExtraProducts[index].quantity
        }
      }
    }

    const financing = patient && patient.Financing
    const paymentPhase = patient && patient.Payment_Phase

    if (patient && patient.CustomerId && isMoons) {
      if (patient.Payment_Status === 'No Payment') {
        if (patient.Final_Price < pricePrincipalProduct) {
          pricePrincipalProduct = patient.Final_Price
        }
      } else if (
        patient.Payment_Status === 'Partial Payment' &&
        patient.Paid_Amount
      ) {
        const diff = patient.Final_Price - patient.Paid_Amount

        if (diff > 0) {
          pricePrincipalProduct = diff
          this.isLiquidation = true
        } else {
          this.handleError({
            message:
              'El precio pagado es mayor o igual al que se intenta cobrar',
          })
        }
      }
    }

    if (category !== 'homekit' && category !== 'appointment') {
      if (financing === 'Moons Pay' && this.country === 'Colombia') {
        if (
          !paymentPhase ||
          paymentPhase === 'Homekit' ||
          paymentPhase === 'Appointment'
        ) {
          pricePrincipalProduct = (60 * pricePrincipalProduct) / 100
          this.isSixtyForty = true
        }
      }
    }

    const { discountTotal } = this.hasDiscount(
      currentDataPrincipal,
      patient,
      pricePrincipalProduct
    )
    pricePrincipalProduct = discountTotal
    // don`t send discount coupon to PrincipalProduct component
    let discountCoupon = pricePrincipalProduct

    let applyCoupon = isMoons
    if (currentCoupon && currentCoupon.isReferralCoupon) {
      if (category === 'homekit' || category === 'appointment') {
        applyCoupon = false
      }
    }

    if (
      currentCoupon &&
      Object.keys(currentCoupon).length !== 0 &&
      applyCoupon
    ) {
      const couponDiscount = currentCoupon.Discount
      if (currentCoupon.Apply_Total) {
        if (currentCoupon.Discount_Type === 'percentage') {
          discountCoupon *= 1 - couponDiscount / 100
        } else {
          discountCoupon -= couponDiscount
        }
      } else if (couponDiscount <= discountCoupon) {
        const patientPaidAmount = patient.Paid_Amount ? patient.Paid_Amount : 0
        const totalLeft = patient.Final_Price - patientPaidAmount
        if (totalLeft > couponDiscount - patientPaidAmount) {
          if (couponDiscount - patientPaidAmount > 0) {
            discountCoupon = couponDiscount - patientPaidAmount
          }
        }
      }
    }

    let externalPromo = false
    if (currentCoupon && currentCoupon.External_Promo) {
      externalPromo = currentCoupon.External_Promo
    }

    const finalPrice = discountCoupon + priceExtra

    this.setState({
      finalPrice,
      pricePrincipalProduct,
      isMoons,
      applyCoupon,
      externalPromo,
    })
  }

  /**
   * Has Discount
   * @param {Object} product .
   * @param {Object} patient .
   * @param {Object} finalPrice .
   * @returns {void} .
   */
  hasDiscount = (product, patient, finalPrice) => {
    let discountTotal = finalPrice
    let hasDiscount =
      product &&
      product.installments &&
      product.installmentsType === 'discount_one_payment'
    if (
      product &&
      product.advancePayment &&
      ((patient && patient.Payment_Status === 'No Payment') || !patient)
    ) {
      return { discountTotal }
    }
    if (patient && patient.Installments) {
      hasDiscount =
        patient &&
        patient.Installments &&
        patient.Installments_Type === 'discount_one_payment'
      if (hasDiscount && patient.Discount_Price) {
        discountTotal = finalPrice * (1 - patient.Discount_Price / 100)
      }
    } else if (hasDiscount && product.discountPrice) {
      discountTotal = finalPrice * (1 - product.discountPrice / 100)
    }
    return { discountTotal }
  }

  getProductsInformation = async () => {
    const { productsKeyName, paymentKeyName, showAddress } = this.props
    let newData = []
    if (productsKeyName && Array.isArray(productsKeyName)) {
      newData = await Promise.all(
        productsKeyName.map((prod) => Services.getProductByKeyName(prod))
      )
    }
    if (paymentKeyName) {
      newData.push(await Services.getPaymentLinkByKeyName(paymentKeyName))
    }

    newData = newData.filter((data) => data)

    if (newData.length === 0) {
      this.setState({ queryParamError: true })
    }

    this.setState({ data: newData })

    if (newData[0]) {
      const currentKeyName = newData[0].paymentKey || newData[0].keyName
      const category = newData && newData[0].category
      let showAddressByProduct

      if (
        this.country === 'Colombia' ||
        this.country === 'Chile' ||
        this.country === 'Peru'
      ) {
        showAddressByProduct = showAddress && category
      } else {
        showAddressByProduct = showAddress && category !== 'appointment'
      }

      this.setState({ currentProduct: currentKeyName, showAddressByProduct })
    }
  }

  getExtraProductsData = async () => {
    const data = await Services.getExtraProductsActive()
    if (data) this.setState({ dataExtra: data })
  }

  /**
   * Set Current Product
   * @param {Object} product .
   * @returns {void} .
   */
  setCurrentProduct = (product) => {
    const { showAddress } = this.props
    const { data } = this.state
    const currentDataPrincipal = data.filter(
      (dataProd) =>
        dataProd.keyName === product || dataProd.paymentKey === product
    )[0]

    if (currentDataPrincipal) {
      const { category } = currentDataPrincipal
      let showAddressByProduct

      if (
        this.country === 'Colombia' ||
        this.country === 'Chile' ||
        this.country === 'Peru'
      ) {
        showAddressByProduct = showAddress && category
      } else {
        showAddressByProduct = showAddress && category !== 'appointment'
      }

      this.setState({ showAddressByProduct })
    }
    this.setState({ currentProduct: product })
  }

  /**
   * Set Extra Current Product
   * @param {Object} products .
   * @returns {void} .
   */
  setExtraProducts = (products) => {
    const { currentExtraProducts } = this.state
    let cloneExtra = { ...currentExtraProducts }
    const extraProducts = []
    if (currentExtraProducts) {
      extraProducts[products.keyName] = { quantity: products.quantity }
    }
    cloneExtra = Object.assign(cloneExtra, extraProducts)
    this.setState({ currentExtraProducts: cloneExtra })
  }

  /**
   * Set Form Data
   * @param {Object} obj .
   * @returns {void} .
   */
  setFormData = (obj) => {
    const { formData } = this.state
    const cloneFormData = { ...formData, ...obj }
    this.setState({ formData: cloneFormData })
  }

  /**
   * Set Form Data
   * @param {Object} data .
   * @returns {void} .
   */
  setToken = (data) => {
    const { tokens } = this.state
    const cloneTokens = { ...tokens, ...data }
    this.setState({ tokens: cloneTokens })
  }

  /**
   * Send To Back
   * @param {Object} formData .
   * @param {Object} tokens .
   * @param {Function} handleSuccess .
   * @param {Function} handleError .
   * @returns {void} .
   */
  sendToBack = (formData, tokens, handleSuccess, handleError) => {
    const { patient } = this.props
    const {
      paymentMethodSelected,
      currentProduct,
      installments,
      currentExtraProducts,
      currentCoupon,
    } = this.state
    const arrayExtraProducts = transformExtraProduct(currentExtraProducts)
    const { monthSelected, type } = installments
    let paymentData = {
      ...tokens,
      country: this.country,
      transactionType: this.isPaymentLink ? 'paymentLink' : 'product',
      paymentMethodType: paymentMethodSelected,
      installments: monthSelected,
      productKeyName: this.isPaymentLink ? null : currentProduct,
      discountPrice: monthSelected === 0 && type === 'discount_one_payment',
      paymentLinkKey: this.isPaymentLink ? currentProduct : null,
      customerId: this.customerId,
      source: patient ? 'link' : 'organic',
      arrayExtraProducts,
      coupon: currentCoupon && currentCoupon.Code,
    }
    if (this.country === 'Colombia') {
      paymentData = {
        ...paymentData,
        cookie: localStorage.getItem('cookie'),
        sessionId: localStorage.getItem('sessionId'),
        deviceSessionId: localStorage.getItem('deviceSessionId'),
      }
    }
    checkoutBack.sendBack(formData, paymentData, handleSuccess, handleError)
  }

  /**
   * Function to handler the error payment
   * @param {Object} response - response of the back when trying a payment
   * @returns {void} .
   */
  handleError = (response) => {
    const { steps, paymentMethodSelected } = this.state
    const currentStep = steps.filter((step) => step.current === true)[0]

    this.setState({ error: response, loader: false })
    if (currentStep.name === INITIAL_STEP) {
      this.submitData(null, true)
    }
    this.tagManager.ErrorEvent(paymentMethodSelected)
  }

  /**
   * Function to handle the success of complete a payment
   * @param {Object} response - success response object
   * @return {void} .
   */
  handleSuccess = async (response) => {
    const { patient } = this.props
    const { formData, paymentMethodSelected, currentCoupon } = this.state
    const email =
      formData && formData.personalData && formData.personalData.email
    const enviroment = process.env.NODE_ENV

    if (patient && patient.Deal_Last_Content && enviroment === 'production') {
      const affiliate = await Services.getAffiliateByCode(
        patient.Deal_Last_Content
      )
      if (affiliate) {
        this.handlePixel(response)
      }
    }
    this.setState({ success: response, loader: false })
    Services.deleteAbandonedCard(email)
    this.tagManager.SuccessEvent(paymentMethodSelected)
    if (currentCoupon) this.tagManager.CouponEvent()
    paymentMethodSelected === 'pagoEfectivo' && window.open(response.externalUrl);
  }

  /**
   * Handle Pixel
   * @param {Object} response .
   * @return {void} .
   */
  handlePixel = (response) => {
    const pixel = document.createElement('img')
    const { paymentMethodSelected } = this.state
    const { patient } = this.props

    const transactionId =
      response &&
      (response.stripePaymentId ||
        response.conektaPaymentId ||
        response.mercadoPagoPaymentId ||
        response.payuPaymentId)

    const email = patient && patient.Email
    const customerId = patient && patient.CustomerId

    const url = `https://affperformance.com/p.ashx?o=319&e=114&t=${transactionId}&ecc=${customerId}&erg=${paymentMethodSelected}&ecv=${email}`
    pixel.setAttribute('src', url)
    document.getElementById('pixelAffiliate').appendChild(pixel)
  }

  /**
   * Create the stripe token
   * @param {*} stripeData - data to create the token
   * @param {*} mercadoPagoData - .
   * @param {*} formData - .
   * @param {*} conektaData - .
   * @param {*} payUData - .
   * @returns {void} .
   */
  createStripeToken = async (
    stripeData,
    mercadoPagoData,
    formData,
    conektaData,
    payUData
  ) => {
    await window.Stripe.card.createToken(stripeData, (status, response) => {
      if (response.error) {
        this.setToken({ stripeId: null })
      } else {
        this.setToken({ stripeId: response.id })
      }

      if (conektaData) {
        this.createConektaToken(conektaData, mercadoPagoData, formData)
      } else if (payUData) {
        this.createPayUToken(payUData, mercadoPagoData, formData)
      }
    })
  }

  /**
   * Create the payUToken
   * @param {String} payUData - the card value
   * @param {String} mercadoPagoData - the name owner
   * @param {Array} formData -  the expiration date [month,year]
   * @returns {void} .
   */
  createPayUToken = async (payUData, mercadoPagoData, formData) => {
    window.payU.getPaymentMethods()
    window.payU.setCardDetails(payUData)
    await window.payU.createToken((response) => {
      if (response.error) {
        this.setToken({ payuId: null })
        this.createMercadoPagoToken(mercadoPagoData, formData)
      } else {
        this.setToken({ payuId: response.token })
        this.createMercadoPagoToken(mercadoPagoData, formData)
      }
    })
  }

  /**
   * Create the mercadoPagoToken
   * @param {String} mercadoPagoData - the card value
   * @param {String} formData - the name owner
   * @returns {void} .
   */
  createMercadoPagoToken = async (mercadoPagoData, formData) => {
    const { card, month, year, cvc, dniType, dniValue } =
      mercadoPagoData.cardData
    const cardNumber = document.getElementById('cardNumber')
    const cardholderName = document.getElementById('cardholderName')
    const cardExpirationMonth = document.getElementById('cardExpirationMonth')
    const cardExpirationYear = document.getElementById('cardExpirationYear')
    const securityCode = document.getElementById('securityCode')
    if (cardNumber) cardNumber.value = card
    if (cardholderName) cardholderName.value = mercadoPagoData.name
    if (cardExpirationMonth) cardExpirationMonth.value = month
    if (cardExpirationYear) cardExpirationYear.value = year
    if (securityCode) securityCode.value = cvc
    if (!IS_MEXICO) {
      const docType = document.getElementById('docType')
      const docNumber = document.getElementById('docNumber')
      if (docType) docType.value = dniType
      if (docNumber)
        docNumber.value = IS_CHILE || IS_PERU ? clean(dniValue) : dniValue
    }
    if (card.length >= 6) {
      const bin = card.substring(0, 6)
      window.Mercadopago.getPaymentMethod(
        { bin },
        (statusPaymentMethodId, responsePaymentMethodId) => {
          if (statusPaymentMethodId === 200) {
            const form = document.querySelector('#pay')
            if (
              responsePaymentMethodId === null ||
              responsePaymentMethodId === undefined
            ) {
              this.setToken({
                cardType: null,
                paymentTypeId: null,
                mercadoPagoId: null,
              })
              this.sendToBack(
                formData,
                this.state.tokens,
                this.handleSuccess,
                this.handleError
              )
            } else if (responsePaymentMethodId.length === 0) {
              this.setToken({
                cardType: null,
                paymentTypeId: null,
                mercadoPagoId: null,
              })
              this.sendToBack(
                formData,
                this.state.tokens,
                this.handleSuccess,
                this.handleError
              )
            } else {
              this.setToken({
                cardType: responsePaymentMethodId[0].id,
                paymentTypeId: responsePaymentMethodId[0].payment_type_id,
              })
              window.Mercadopago.createToken(form, (status, response) => {
                if (status === 200 || status === 201) {
                  this.setToken({ mercadoPagoId: response.id })
                } else {
                  this.setToken({ mercadoPagoId: null })
                }
                this.sendToBack(
                  formData,
                  this.state.tokens,
                  this.handleSuccess,
                  this.handleError
                )
              })
            }
          } else {
            this.setToken({ cardType: null })
            this.setToken({ mercadoPagoId: null })
            this.sendToBack(
              formData,
              this.state.tokens,
              this.handleSuccess,
              this.handleError
            )
          }
        }
      )
    }
  }

  /**
   * Create the conekta token
   * @param {Object} conektaData - the object containing the data to create the token
   * @param {Object} mercadoPagoData - .
   * @param {Object} formData -.
   * @returns {void} .
   */
  createConektaToken = async (conektaData, mercadoPagoData, formData) => {
    await window.Conekta.Token.create(
      conektaData,
      (successEvent) => {
        this.setToken({ conektaId: successEvent.id })
        this.createMercadoPagoToken(mercadoPagoData, formData)
      },
      (errorEvent) => {
        console.error(errorEvent)
        this.setToken({ conektaId: null })
        this.createMercadoPagoToken(mercadoPagoData, formData)
      }
    )
  }

  /**
   * Generate all the key tokens to send to the back
   * @param {object} formData .
   * @param {object} cardData .
   * @param {Object} setToken .
   * @return {void} .
   */
  generateAllKey = (formData, cardData) => {
    const { cardName } = cardData

    const cardInfo = {
      name: cardName,
      number: cardData.card,
      cvc: cardData.cvc,
      exp_month: cardData.month,
      exp_year: cardData.year,
    }

    const conektaData = {
      card: {
        ...cardInfo,
      },
    }
    const stripeData = {
      ...cardInfo,
      address_country: this.country,
    }

    const mercadoPagoData = {
      cardData,
      name: cardName,
    }
    if (IS_MEXICO) {
      this.createStripeToken(stripeData, mercadoPagoData, formData, conektaData)
    } else if (IS_COLOMBIA) {
      let { payerId } = formData
      if (!payerId) {
        payerId = new Date().getTime().toString()
      }
      payerId = md5(formData.name + payerId)
      const method = window.payU.cardPaymentMethod(cardData.card)
      const payUData = {
        payer_id: payerId,
        number: cardData.card,
        name_card: formData.name,
        exp_month: cardData.month,
        exp_year: cardData.year,
        cvv: cardData.cvc,
        method,
      }
      this.createStripeToken(
        stripeData,
        mercadoPagoData,
        formData,
        null,
        payUData
      )
    } else if (IS_CHILE || IS_PERU) {
      this.createMercadoPagoToken(mercadoPagoData, formData)
    }
  }

  /**
   * Handle Submit
   * @param {object} response .
   * @return {void} .
   */
  handleSubmit = (response) => {
    let utm = false
    const history = this.state.history
    if ((history && history.location && history.location.search)) {
      const { utm_source, utm_medium, utm_term, utm_content, utm_campaign } = queryString.parse(history.location.search)
      if (utm_source) {
        utm = utm ? utm : {}
        utm.utm_source = utm_source
      }
      if (utm_medium) {
        utm = utm ? utm : {}
        utm.utm_medium = utm_medium
      }

      if (utm_term) {
        utm = utm ? utm : {}
        utm.utm_term = utm_term
      }

      if (utm_content) {
        utm = utm ? utm : {}
        utm.utm_content = utm_content
      }

      if (utm_campaign) {
        utm = utm ? utm : {}
        utm.utm_campaign = utm_campaign
      }
    }

    if (!this.customerId) {
      this.customerId = response && response.CustomerId
    }
    setAnalyticsUserId(this.customerId)
    if (!this.referralCode) {
      this.referralCode = response && response.Referral_Code
    }
    const { formData, formPSE } = this.state
    const { payment, personalData, address } = formData
    const { bank, docType, docValue, clientType } = formPSE
    const { dniType, dniValue } = payment
    const dataToSend = {
      address,
      ...personalData,
      utm,
      codeBankPSE: bank,
      userTypePSE: clientType,
      docTypePSE: docType,
      docValuePSE: docValue,
      payerId: this.customerId,
      docTypeCard: dniType,
      docValueCard: IS_CHILE || IS_PERU ? clean(dniValue) : dniValue,
    }

    const { salesAgentId } = this.props

    if (salesAgentId) dataToSend.salesAgentId = salesAgentId

    if (this.state.paymentMethodSelected === 'card') {
      this.generateAllKey(dataToSend, payment)
    } else {
      this.sendToBack(dataToSend, null, this.handleSuccess, this.handleError)
    }
  }

  showFullLoader = () => {
    let showLoader = true
    const { productsKeyName, paymentKeyName, extraProductsAllowed } = this.props
    const { data, dataExtra } = this.state
    if (
      (productsKeyName && data.length > 0) ||
      (paymentKeyName && data.length > 0)
    ) {
      if (
        (extraProductsAllowed && dataExtra.length > 0) ||
        !extraProductsAllowed
      ) {
        showLoader = false
      }
    }
    return showLoader
  }

  /**
   * Component Render
   * @return {void} .
   */
  render() {
    const {
      currentProduct,
      finalPrice,
      data,
      dataExtra,
      currentExtraProducts,
      steps,
      currentCoupon,
      formError,
      paymentMethodSelected,
      formData,
      pricePrincipalProduct,
      installments,
      formPSE,
      success,
      error,
      queryParamError,
      loader,
      monthsAllowed,
      showAddressByProduct,
      applyCoupon,
      externalPromo,
    } = this.state

    const {
      showCoupon,
      showSend,
      patient,
      selector,
      extraProductsAllowed,
      isReferralFlow,
      urlCoupon,
    } = this.props

    if (queryParamError) {
      return <ErrorPage />
    }

    const currentStep = steps.filter((step) => step.current === true)[0]

    const currentDataPrincipal = data.filter(
      (dataProd) =>
        dataProd.keyName === currentProduct ||
        dataProd.paymentKey === currentProduct
    )

    const isAdvance =
      currentDataPrincipal &&
      currentDataPrincipal[0] &&
      currentDataPrincipal[0].advancePayment

    const category =
      currentDataPrincipal &&
      currentDataPrincipal[0] &&
      currentDataPrincipal[0].category

    const isMoons =
      currentDataPrincipal &&
      currentDataPrincipal[0] &&
      currentDataPrincipal[0].productType === 'moons'

    const uploadPictures = patient && patient.Pictures_Uploaded

    const couponData = {
      currentCoupon: { ...currentCoupon },
      setCoupon: (coupon) => this.setState({ currentCoupon: { ...coupon } }),
      showCoupon: isReferralFlow
        ? isMoons
        : showCoupon && !isAdvance && isMoons && category === 'moons',
      applyCoupon,
    }

    const showHeader =
      (success &&
        (!paymentMethodSelected ||
          (paymentMethodSelected !== 'card' &&
            paymentMethodSelected !== 'pse'))) ||
      (!error && !success)

    const textByPayment = PaymentServices.getPaymentButtonTextLabel(
      paymentMethodSelected
    )
    const paymentButtonTextLabel =
      currentStep.name === PAYMENTS_STEP ? textByPayment : messages.textButton

    const patientName = (patient && patient.Patient_Name) || ''
    let submitClass;
    switch (currentStep.name) {
      case INITIAL_STEP:
        submitClass = styles.InitialButton
        break;
      case ADDRESS_STEP:
        submitClass = styles.AddressButton
        break;
      case PAYMENTS_STEP:
        submitClass = styles.PayButton
        break;
      default:
        submitClass = styles.SubmitButton
        break;
    }

    let forwardButton = (
      <div className={styles.FormSubmitButton}>
        <div
          role="button"
          tabIndex={0}
          className={submitClass}
          onClick={() => this.submitData()}
        >
          {paymentButtonTextLabel}
        </div>
      </div>
    )
    if (
      (currentStep.name === PAYMENTS_STEP && paymentMethodSelected === '') ||
      error ||
      success ||
      loader
    ) {
      forwardButton = null
    }

    const address =
      this.refCheckoutForm &&
      this.refCheckoutForm.current &&
      formData &&
      formData.address

    if (this.showFullLoader()) {
      return <LoaderFullScreen />
    }

    return (
      <div className={styles.Container} ref={this.refContainer}>
        <div className={styles.Right}>
          <div className={styles.PixelAffiliate} id="pixelAffiliate" />
          <div className={styles.Wrapper}>
            {showHeader && (
              <Header
                right
                products={{ data }}
                currentProduct={currentProduct}
                setCurrentProduct={this.setCurrentProduct}
                currentStep={currentStep}
                stepBack={(back) => this.submitData(back)}
                pricePrincipalProduct={patient ? pricePrincipalProduct : false}
                couponData={couponData}
                isPaymentLink={this.isPaymentLink}
                installmentsData={installments}
                isLiquidation={this.isLiquidation}
                patientName={patientName}
                paymentMethodSelected={paymentMethodSelected}
                success={success}
                error={error}
                externalPromo={externalPromo}
                isSixtyForty={this.isSixtyForty}
              />
            )}
            {currentStep.name === INITIAL_STEP ? (
              !this.isPaymentLink &&
              extraProductsAllowed && (
                <>
                  <ExtraProducts
                    currentExtraProducts={currentExtraProducts}
                    products={{ dataExtra }}
                    setProducts={(products) => this.setExtraProducts(products)}
                  />
                  <hr className={styles.SeparatorExtra} />
                  <TotalPrice price={finalPrice} />
                </>
              )
            ) : (
              <>
                <CheckoutForms
                  ref={this.refCheckoutForm}
                  refContainer={this.refContainer}
                  country={this.country}
                  currentStep={currentStep}
                  customerId={this.customerId}
                  publicKey={this.publicKey}
                  userDashboard={this.userDashboard}
                  formError={formError}
                  steps={steps}
                  formData={formData}
                  setFormData={this.setFormData}
                  selector={selector}
                  dataExtra={this.isPaymentLink ? [] : dataExtra}
                  paymentMethodSelected={paymentMethodSelected}
                  onChangePaymentSelection={this.handlePaymentSelection}
                  installmentsData={installments}
                  monthsAllowed={monthsAllowed}
                  currentProduct={currentDataPrincipal[0]}
                  currentExtraProducts={currentExtraProducts}
                  pricePrincipalProduct={pricePrincipalProduct}
                  isAdvance={isAdvance}
                  finalPrice={finalPrice}
                  showAddress={showAddressByProduct}
                  showSend={showSend && category !== 'appointment'}
                  changeFinalPrice={this.changeFinalPriceByInstallments}
                  setPSEData={this.setPSEData}
                  couponReferrals={this.referralCode}
                  category={category}
                  uploadPictures={uploadPictures}
                  isSixtyForty={this.isSixtyForty}
                  patientName={patientName}
                  isLiquidation={this.isLiquidation}
                  formPSE={formPSE}
                  success={success}
                  error={error}
                  loader={loader}
                  history={this.props.history}
                />
              </>
            )}
            {forwardButton}
          </div>
          <Footer country={this.country} />
        </div>
        {/* Header Mobile */}
        {showHeader && (
          <Header
            products={{ data }}
            currentProduct={currentProduct}
            setCurrentProduct={this.setCurrentProduct}
            currentStep={currentStep}
            currentExtraProducts={currentExtraProducts}
            stepBack={(back) => this.submitData(back)}
            dataExtra={this.isPaymentLink ? [] : dataExtra}
            setExtraProducts={(products) => this.setExtraProducts(products)}
            finalPrice={finalPrice}
            pricePrincipalProduct={patient ? pricePrincipalProduct : false}
            couponData={couponData}
            isPaymentLink={this.isPaymentLink}
            isLiquidation={this.isLiquidation}
            installmentsData={installments}
            patientName={patientName}
            paymentMethodSelected={paymentMethodSelected}
            urlCoupon={urlCoupon}
            externalPromo={externalPromo}
            success={success}
            error={error}
          />
        )}
        <div className={styles.Left}>
          <div className={styles.SquareContainer}>
            <div className={styles.Square} />
          </div>
          <ProductInformation
            currentProduct={currentDataPrincipal[0]}
            currentExtraProducts={currentExtraProducts}
            dataExtra={this.isPaymentLink ? [] : dataExtra}
            setExtraProducts={
              success || error
                ? null
                : (products) => this.setExtraProducts(products)
            }
            finalPrice={finalPrice}
            pricePrincipalProduct={pricePrincipalProduct}
            showSend={showSend && category !== 'appointment'}
            couponData={couponData}
            isPaymentLink={this.isPaymentLink}
            installmentsData={installments}
            wasSendToBack={success || error}
            isLiquidation={this.isLiquidation}
            patientName={patientName}
            isSixtyForty={this.isSixtyForty}
            urlCoupon={urlCoupon}
          />
          {currentStep.name === PAYMENTS_STEP &&
            address &&
            showAddressByProduct && (
              <AddressSaved address={address} category={category} />
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(Checkout)
