/* eslint-disable max-statements */
import React from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import ErrorPage from '@mymoons/error-page'
import Checkout from '../Checkout/Checkout'
import Services from '../../Apis/Back4AppServices'
import products from '../../Utils/products'
import TimeCounter from '../FullModal/TimeCounter'

const countryRefs = {
  México: {
    booking: process.env.REACT_APP_BOOKING_URL_MX,
  },
  Colombia: {
    booking: process.env.REACT_APP_BOOKING_URL_CO,
  },
  Chile: {
    booking: process.env.REACT_APP_BOOKING_URL_CL,
  },
  Peru: {
    booking: process.env.REACT_APP_BOOKING_URL_PE,
  },
}

/**
 * CheckoutFromPaymentId Component
 * @returns {void} .
 */
class CheckoutFromPaymentId extends React.Component {
  /**
   * CheckoutFromPaymentId constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    let query = {}
    if (props && props.location && props.location.search) {
      query = queryString.parse(props.location.search)
    }
    const appointmentTypeId = query.appointmentTypeId
      ? query.appointmentTypeId
      : false

    this.state = {
      success: null,
      loading: true,
      expired: false,
      patient: {},
      appointmentTypeId,
    }
    const { match } = this.props
    const { path } = match
    const params = match.params ? match.params : null

    this.country = process.env.REACT_APP_COUNTRY
    let countryCode = ''
    switch (this.country) {
      case 'México':
        countryCode = 'MX'
        break
      case 'Colombia':
        countryCode = 'CO'
        break
      case 'Chile':
        countryCode = 'CL'
        break
      case 'Peru':
        countryCode = 'PE'
        break
      default:
        countryCode = 'MX'
        break
    }
    this.product = params.product ? params.product : null
    this.paymentId = params.paymentId ? params.paymentId : null
    this.coupon = params.coupon ? params.coupon : null
    this.salesAgentId = params.salesAgentId ? params.salesAgentId : null
    this.customerData = {}
    this.productKeyName = products[countryCode][this.product]
      ? products[countryCode][this.product]
      : false
    this.isReferralFlow = path.indexOf('referral') !== -1
  }

  /**
   * componentDidMount
   * @returns {void} .
   */
  async componentDidMount() {
    const patientData = await Services.getPatientByPaymentId(this.paymentId)
    this.setPatient(patientData)
    const countryPatient = patientData && patientData.Country_Ops

    if (this.country !== countryPatient) {
      this.changeLoading(false, false)
    } else if (patientData) {
      this.customerData = {
        name: patientData.Patient_Name,
        email: patientData.Email,
        phone: patientData.Phone,
        paymentStatus: patientData.Payment_Status,
      }
      this.changeLoading(true, false)
    } else {
      this.changeLoading(false, false)
    }
  }

  /**
   * Set Patient Data
   * @param {Object} patientData .
   * @returns {void} .
   */
  setPatient = (patientData) => {
    this.setState({ patient: patientData })
  }

  /**
   * changeLoading
   * @param {Object} success .
   * @param {Object} loading .
   * @returns {void} .
   */
  changeLoading = (success, loading) => {
    this.setState({ success, loading })
  }

  setExpired = () => {
    this.setState({ expired: true })
  }

  clearPopupTimer = () => {
    const publicKey = this.state.patient.PublicKey
    const bookingUrl = countryRefs[this.country].booking
    window.location.href = `${bookingUrl}/${publicKey}?appointmentTypeId=${this.state.appointmentTypeId}`
  }

  /**
   * CheckoutFromPaymentId render
   * @returns {void} .
   */
  render() {
    const {
      paymentKeyName,
      isAdvance,
      showPersonalData,
      showAddress,
      showCoupon,
      showProductInformation,
      extraProductsAllowed,
      showSend,
      selector,
    } = this.props
    const { success, loading, patient } = this.state

    const isPartialPayment =
      this.customerData.paymentStatus === 'Partial Payment'

    /**
     * ValidatePaymentByCountry const
     * @returns {boolean} .
     */
    const validatePaymentByCountry = () => {
      if (this.country === 'Colombia') {
        return true
      }
      return !isPartialPayment
    }

    if (!loading) {
      if (!success || !this.productKeyName) {
        return <ErrorPage />
      }

      return (
        <>
          {this.state.appointmentTypeId ? (
            <TimeCounter
              setExpired={this.setExpired}
              clearPopup={this.clearPopupTimer}
            />
          ) : null}
          {!this.state.expired ? (
            <Checkout
              productsKeyName={[this.productKeyName]}
              paymentKeyName={paymentKeyName}
              patient={patient}
              isAdvance={isAdvance}
              showPersonalData={showPersonalData}
              showAddress={showAddress && validatePaymentByCountry}
              showCoupon={showCoupon}
              showProductInformation={showProductInformation}
              customerData={this.customerData}
              extraProductsAllowed={extraProductsAllowed}
              showSend={showSend}
              selector={selector}
              isReferralFlow={this.isReferralFlow}
              urlCoupon={this.coupon}
              salesAgentId={this.salesAgentId}
            />
          ) : null}
        </>
      )
    }
    return <></>
  }
}

export default withRouter(CheckoutFromPaymentId)
